<template>
	<div class="input-container">
		<h2>{{ languageStrings.phoneNumber }}</h2>
		<div id="country-dropdown-container">
			<p>{{ languageStrings.selectTheCountryCodeForYourPhoneNumber }}</p>
			<CountryDropdownMenu
				v-if="countryList?.length > 0"
				:systemSettings="systemSettings"
				:playerState="playerState"
				:countryList="countryList"
				:languageStrings="languageStrings"
				:countryDropdownType="`PhoneNumber`"
			/>
		</div>
		<div id="phone-container">
			<div class="input-container">
				<p>{{ languageStrings.pleaseEnterAValidPhoneNumberForVerification }}</p>
				<input
					class="curved-border"
					type="number"
					:placeholder="languageStrings.phoneNumber"
					v-model="phoneNumber"
				/>
			</div>
		</div>
		<div class="encore-style-button-container">
			<button
				class="btn curved-border encore-style-button"
				:title="languageStrings.continue"
				@click="submitToParent()"
			>
				{{ languageStrings.continue }}
			</button>
		</div>
	</div>
</template>

<script>
import { onBeforeUnmount } from "vue";
import CountryDropdownMenu from "@/components/CountryDropdownMenu";

export default {
	name: "PhoneNumberForm",
	props: {
		systemSettings: Object,
		playerState: Object,
		countryList: Array,
		languageStrings: Object,
		languageErrorStrings: Object,
	},
	components: {
		CountryDropdownMenu,
	},
	data() {
		return {
			status: Object.assign({}, this.globalStatus),
			phoneNumber: "",
			newAccountProperty: {
				propertyName: "",
				propertyValue: "",
				componentName: "PhoneNumberForm",
			},
		};
	},
	watch: {},
	created() {
		this.eventBus.on("countrySelected", (payload) => {
			this.selectedCountry = payload;
		});
		onBeforeUnmount(() => {
			this.eventBus.off("countrySelected");
		});
	},
	mounted() {},
	methods: {
		submitToParent() {
			if (!this.phoneNumber) {
				this.status.ok = false;
				this.status.message = this.languageErrorStrings.provideValidPhone;
				this.eventBus.emit("updateStatus", this.status);
				return false;
			}

			this.newAccountProperty.propertyName = "phoneNumber";
			this.newAccountProperty.propertyValue = this.phoneNumber;
			this.eventBus.emit("addNewAccountProperty", this.newAccountProperty);

			this.eventBus.emit("advanceComponent");
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#phone-container {
	display: flex;
	flex-direction: column;
}

#country-dropdown-container {
	max-width: 400px;
	margin: 15px auto;
}

.input-container {
	display: flex;
	flex-direction: column;
	margin: 15px auto;
	padding: 15px;
}

input {
	font-size: 1.5em;
	color: #6305c9;
}

.encore-style-button-container {
	margin-top: 2vh;
}

/* .btn {
	height: 4.5vh !important;
} */

.curved-border {
	height: 3.5vh;
}
</style>
