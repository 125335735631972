<template>
	<div id="verification-inputs">
		<p>{{ languageStrings.enterCode }}</p>
		<div class="input-container">
			<!-- <input v-model="verificationCode" type="text" placeholder="Verification Code" maxlength="6" required /> -->
			<div id="input-group">
				<input
					v-for="(input, index) in inputs"
					:key="index"
					type="number"
					:tabindex="index + 1"
					autocomplete="one-time-code"
					:id="`input${index}`"
					maxlength="1"
					v-model="inputs[index]"
					@input="handleInput($event)"
					@paste="onPaste($event)"
					@keydown.delete="handleDelete($event)"
				/>
			</div>
			<div class="encore-style-button-container">
				<button class="btn curved-border encore-style-button" @click="verifyPhoneNumber()">
					{{ languageStrings.submit }}
				</button>
			</div>
			<!-- <button class="btn" @click="requestVerificationCode()">{{ languageStrings.resendCode }}</button> -->
		</div>
	</div>
</template>

<script>
import router from "@/router";
import sharedScripts from "@/dependencies/sharedScripts";

export default {
	name: "PhoneVerification",
	props: {
		playerState: Object,
		newAccountProperties: Object,
		languageStrings: Object,
		languageErrorStrings: Object,
	},
	components: {},
	data() {
		return {
			status: Object.assign({}, this.globalStatus),
			inputs: Array(6),
			verificationCode: "",
			dataFromPaste: "",
		};
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		handleDelete(e) {
			//keydown event = move to previous element then only delete number

			let value = e.target.value;
			let currentActiveElement = e.target;
			if (!value) currentActiveElement.previousElementSibling?.focus();
		},
		handleInput(e) {
			const inputType = e.inputType;
			let currentActiveElement = e.target;

			if (inputType === "insertText") currentActiveElement.nextElementSibling?.focus();

			if (inputType === "insertFromPaste" && this.dataFromPaste) {
				for (const num of this.dataFromPaste) {
					let id = Number(currentActiveElement.id.split("input")[1]);
					currentActiveElement.value = num;
					if (currentActiveElement.nextElementSibling) {
						currentActiveElement = currentActiveElement.nextElementSibling;
						currentActiveElement.nextElementSibling?.focus();
					}
				}
			}
		},
		onPaste(e) {
			this.dataFromPaste = e.clipboardData?.getData("text").trim().split("");
		},
		async verifyPhoneNumber() {
			this.verificationCode = this.inputs.join("");
			let body = {
				PhoneNumber: this.newAccountProperties.phoneNumber.toString(),
				Code: this.verificationCode,
			};

			let requestUrl = new URL("/api/v1/user/verification", this.rabbitsfootHostUrl);
			let headerObj = new Headers();
			// headerObj.append("Authorization", `Bearer ${this.playerState.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let request = new Request(requestUrl.toString(), {
				method: "POST",
				body: JSON.stringify(body),
				headers: headerObj,
			});

			try {
				const response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response, this.languageErrorStrings);

				if (fetchStatus && !fetchStatus.ok) {
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout === true) this.eventBus.emit("forceLogout");
					return;
				}

				let dataJson = await response.json();

				if (fetchStatus.ok)
					this.status = sharedScripts.checkSuccessErrors(dataJson.status, this.languageErrorStrings);

				if (dataJson?.status === "Success") {
					this.verificationSuccess = true;
					router.push("/");
				}

				this.eventBus.emit("updateStatus", this.status);
			} catch (e) {
				console.error(e);
			}
		},
		async requestVerificationCode() {
			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverBusy = false;
				this.busyText = "";
				return false;
			}

			let _state = this.playerState;

			let requestUrl = new URL("/api/v1/user/verification/code", this.rabbitsfootHostUrl);
			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${this.playerState.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let request = new Request(requestUrl.toString(), {
				method: "POST",
				headers: headerObj,
			});

			try {
				const response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response, this.languageErrorStrings);

				if (fetchStatus && !fetchStatus.ok) {
					fetchStatus.message =
						fetchStatus.code === 404 ? this.languageErrorStrings.phoneNotFound : fetchStatus.message;
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout === true) this.eventBus.emit("forceLogout");
					return;
				}

				let dataJson = await response.json();

				switch (dataJson?.status) {
					case "DeniedByAccountState":
						this.status.ok = false;
						this.status.message = this.languageErrorStrings.smsCodeNotSent;
						this.eventBus.emit("updateStatus", this.status);
						return;
					case "FailedToSend":
						this.status.ok = false;
						this.status.message = `${this.languageErrorStrings.unableToSendSMS} ${this.playerState.phoneNumber}.`;
						this.eventBus.emit("updateStatus", this.status);
						return;
					case "Success":
						this.status.message = `${this.languageErrorStrings.smsCodeSentTo} ${this.playerState.phoneNumber}.`;
						this.codeSent = true;
						this.eventBus.emit("updateStatus", this.status);
				}
			} catch (e) {
				console.error(e);
			}
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#verification-inputs {
	max-width: 400px;
	margin: 15px auto;
}

.input-container {
	display: flex;
	flex-direction: column;
	margin: 15px;
	font-size: 1.5em;
}

#input-group {
	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
}

input {
	width: 1em;
	font-size: 1em;
	margin: 2px;
	text-align: center;
	border-radius: 5px;
	color: #6305c9;
}

.encore-style-button-container {
	margin-top: 2vh;
}

@media (min-width: 380px) {
	input {
		font-size: 1.5em;
		margin: 5px;
	}
}
</style>
